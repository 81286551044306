<template>
  <BaseTableWrapper :columns="columns" :table-init="tableInit">
    <h1 class="page-header">Payments</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import PaymentsListDetail from '@/components/AdminPaymentsListDetail.vue'
import PaymentsTableActions from '@/components/AdminPaymentsTableActions.vue'
import paymentsActionsWrapper from '@/components/AdminPaymentsActionsWrapper'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import { currencyFilter } from '@/utils/currency'

export default {
  components: {
    BaseTableWrapper,
    // eslint-disable-next-line
    PaymentsListDetail,
  },
  metaInfo() {
    return {
      title: 'Payments',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'paymentId',
        tableId: 'payments_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        detail: PaymentsListDetail,
        isDetailed: true,
        columns: [],
        collection: 'Payments',
      },
      columns: [],
    }
  },
  async mounted() {
    const wrappedActions = await paymentsActionsWrapper(PaymentsTableActions)
    const columns = [
      {
        _t_id: '6cf09066',
        prop: '/',
        text: 'Actions',
        component: wrappedActions,
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: 'aaf5c93a',
        prop: 'paymentId',
        text: 'Payment ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'paymentId',
        defaultSort: true,
        detail: false,
      },
      {
        _t_id: 'aaf5cc28',
        prop: 'accountId',
        text: 'Account ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'accountId',
        detail: false,
      },
      {
        _t_id: 'aaf5cda4',
        prop: 'companyName',
        text: 'Company Name',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'companyName',
        detail: false,
      },
      {
        _t_id: 'aaf5cee4',
        prop: 'amount',
        text: 'Amount',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'amount',
        computedText: (item) => currencyFilter(item),
        detail: false,
      },
      {
        _t_id: 'aaf5d024',
        prop: 'paymentType',
        text: 'Payment Type',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'paymentType',
        detail: false,
      },
      {
        _t_id: 'aaf5d39e',
        prop: 'paymentSystem',
        text: 'Payment System',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'paymentSystem',
        detail: false,
      },
      {
        _t_id: 'aaf5d740',
        prop: 'referenceNumber',
        text: 'Reference Number',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'referenceNumber',
        detail: false,
      },
      {
        _t_id: 'sssssss',
        prop: '/',
        text: 'Reservation ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        filterProp: 'credits.reservationId',
        computedText: this.getReservationIds,
        sortProp: 'referenceNumber',
        detail: false,
      },
      {
        _t_id: 'aaf5d4e8',
        prop: 'createdOn',
        text: 'Created On',
        sort: true,
        filter: true,
        type: 'date',
        filterType: 'eq',
        sortProp: 'createdOn',
        computedText: (item) =>
          item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
        predefined: deepClone(datePredefined),
        detail: false,
      },
      {
        _t_id: 'aaf5d614',
        prop: 'createdBy',
        text: 'Created By',
        sort: true,
        type: 'text',
        filter: true,
        filterType: 'contains',
        sortProp: 'createdBy/lastName',
        detail: false,
      },
      {
        _t_id: 'aaf5d862',
        prop: 'receiptId',
        text: 'Receipt ID',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'receiptId',
        detail: false,
      },
      {
        _t_id: '0676b53c',
        prop: 'voidFlag',
        text: 'Void',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sortProp: 'voidFlag',
        customFilterTabDisplay: (val) => {
          return val === 1 ? 'Yes' : 'No'
        },
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 1,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: 0,
          },
        ],
        detail: false,
      },
    ]
    this.columns = columns
  },
  methods: {
    getReservationIds(item) {
      if (item.credits === null) return ''
      const reservationIdsObject = item.credits
        .map((c) => c.reservationId)
        .filter((c) => c)
        .reduce((a, b) => {
          a[b] = true
          return a
        }, {})
      return Object.keys(reservationIdsObject).join(', ')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.status-filters .status-filter-select {
  max-width: 350px !important;
}
</style>
