import { round } from './round'

/**
 * Formats a numeric value as a currency string.
 *
 * This function takes a numeric or string representation of a number and formats it
 * according to the specified currency and locale ('en-US'). It also provides an option
 * to hide decimal places in the formatted currency string.
 *
 * @param input - The numeric value to format, either as a number or a string.
 * @param currency - The ISO 4217 currency code to format the number as. Defaults to 'USD'.
 * @param hideDecimals - A boolean indicating whether to hide decimal places. Defaults to false.
 * @returns The formatted currency string.
 */
export const currencyFilter = (
  input: number | string,
  currency: string = 'USD',
  hideDecimals: boolean = false
): string => {
  let renderNum: number = isNaN(Number(input)) ? 0 : Number(input)
  if (hideDecimals) {
    renderNum = parseFloat(renderNum.toFixed(0))
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  })
  let formattedNumber = formatter.format(renderNum)
  if (hideDecimals) {
    formattedNumber = formattedNumber
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      .replace(/\.\d\d/g, '')
  }

  return formattedNumber
}

/**
 * Converts a currency string to a numeric value.
 *
 * This function is designed to parse strings that represent monetary values,
 * returning their numeric equivalent. It attempts to extract a numeric value
 * from a formatted currency string by removing any currency symbols, commas,
 * and other non-numeric characters. If the resulting  cleaned string is empty
 * or cannot be converted to a valid number, the function returns `undefined`.
 * Otherwise, it converts the cleaned string to a number and rounds the result
 * to two decimal places.
 *
 * It's especially useful for processing user
 * input or data that may include formatted currency values.
 *
 * @param input - The currency string to convert to a number. This can include
 *                symbols, commas, and other non-numeric characters typically found
 *                in formatted currency strings.
 * @returns The numeric value extracted and rounded from the input string, or
 *          `undefined` if the input is falsy, does not contain any numeric value,
 *          or cannot be converted to a valid number.
 */
export const currencyToNumber = (input: string): number | undefined => {
  if (!input) {
    return undefined
  }

  // Remove any characters that are not digits, a minus sign, or a dot.
  const cleanedInput = input.replace(/[^0-9.-]+/g, '')

  // Check if the cleaned input is empty or if it's not a valid number.
  if (cleanedInput === '' || isNaN(Number(cleanedInput))) {
    return undefined
  }

  return round(Number(cleanedInput), 2)
}
