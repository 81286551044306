import objectPath from 'simple-object-path'

export function columnDisplay(row, column, defaultValue) {
  if (Array.isArray(column.prop) && !column.computedText) {
    return column.prop.map((prop) => objectPath(row, prop)).join(' ')
  } else if (column.computedText) {
    return column.computedText(
      column.prop === '/' ? row : objectPath(row, column.prop),
      row
    )
  }
  return objectPath(row, column.prop) || defaultValue
}
