<template>
  <v-container
    v-if="showTableHeader"
    fluid
    :class="hasCallCenter ? 'call-center-margin' : ''"
  >
    <v-layout :class="{ sheet: !removePadding }" column>
      <slot>
        <h1 class="page-header"></h1>
      </slot>

      <DataTable
        v-bind.sync="tableProps"
        :add-new-button-label="addNewButtonLabel"
        v-on="$listeners"
      >
        <template slot="additionalActions">
          <slot name="additionalActions"></slot>
        </template>
        <template slot="multiSelectActions">
          <slot name="multiSelectActions"></slot>
        </template>
      </DataTable>
    </v-layout>
  </v-container>
  <DataTable v-else v-bind.sync="tableProps" v-on="$listeners">
    <template slot="additionalActions">
      <slot name="additionalActions"></slot>
    </template>
    <template slot="multiSelectActions">
      <slot name="multiSelectActions"></slot>
    </template>
  </DataTable>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { calculatedValues } from '@/utils/predefined'
import { v4 } from 'uuid'

export default {
  name: 'BaseTableWrapper',
  components: {
    DataTable,
  },
  props: {
    showTableHeader: {
      type: Boolean,
      default: true, // eslint-disable-line
    },
    initialFilters: {
      type: Array,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    specialTextFormatting: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    columns: {
      type: Array,
      default: () => [],
    },
    removePadding: {
      type: Boolean,
    },
    addNewButtonLabel: {
      type: String,
      default: 'Add New',
    },
    tableInit: {
      initialFilters: {
        type: Array,
        default: () => [],
      },
      type: Object,
      required: true,
      enableStatusFilterToggle: {
        type: Boolean,
        default: true,
      },
      enableSelectableRows: {
        type: Boolean,
        default: false,
      },
      enableExport: {
        type: Boolean,
        default: false,
      },
      enableExportOverride: {
        type: Boolean,
        default: false,
      },
      exportAction: {
        type: String,
        default: '',
      },
      exportName: {
        type: String,
        default: '',
      },
      enableImport: {
        type: Boolean,
        default: false,
      },
      enableAdditionalActions: {
        type: Boolean,
        default: false,
      },
      enableSavedViews: {
        type: Boolean,
        default: true,
      },
      addNewEnabled: {
        type: Boolean,
        default: false,
      },
      forceRefresh: {
        type: Number,
        default: 0,
      },
      areStatusFiltersShowing: {
        type: Boolean,
        default: false,
      },
      categories: {
        type: Array,
        default: [],
      },
      addNewHandler: {
        type: Function,
        default: () => () => {},
      },
      externalShareFilters: {
        type: Function,
        default: () => () => {},
      },
      externalShareSorts: {
        type: Function,
        default: () => () => {},
      },
      importTable: {
        type: Function,
        default: () => () => {},
      },
      collection: {
        type: String,
        required: true,
      },
      vuexAction: {
        type: String,
        required: false,
      },
      action: {
        type: Function,
        default: null,
      },
      detail: {
        required: true,
      },
      detailKeyId: {
        type: String,
        required: true,
      },
      columns: {
        required: true,
        type: Array,
        default: [],
      },
      additionalQueries: {
        required: false,
        type: String,
        default: null,
      },
    },
  },
  data() {
    const tablePropsDefaults = {
      sort: this.sort,
      changePage: this.changePage,
      addFilter: this.addFilter,
      removeFilter: this.removeFilter,
      setSort: this.setSort,
      isDetailed: false,
      shareFilters: this.receiveFilters,
      shareSorts: this.receiveSorts,
      detailKeyId: this.detailKeyId,
      loading: true,
      isAdmin: false,
      enableFilterRow: true,
      enableExport: false,
      enableImport: false,
      enableColumnConfig: false,
      enableSavedViews: true,
      enableStatusFilterToggle: true,
      currentPage: 1,
      perPage: 10,
      list: [],
      calculatedValues,
    }
    const tableProps = Object.assign(tablePropsDefaults, this.tableInit)

    return {
      latestRequestId: null,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      user: {},
      itemsPerPage: 10,
      page: 1,
      tableProps,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    initialFilters() {
      this.setInitialFilters()
    },
    isAdmin() {
      this.tableProps.isAdmin = this.isAdmin
    },
    columns() {
      this.tableProps.columns = this.columns
    },
    'tableInit.forceRefresh'(val) {
      this.tableProps.forceRefresh = val
    },
    'tableInit.enableExport'(val) {
      if (val) {
        this.enableExport()
      }
    },
  },
  mounted() {
    if (this.tableInit.enableExport) {
      this.enableExport()
    }
    this.setInitialFilters()

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
  },
  methods: {
    setInitialFilters() {
      if (this.initialFilters && this.initialFilters.length) {
        this.tableProps.initialFilters = this.initialFilters
      }
    },
    receiveFilters(filters) {
      this.filters = filters
      if (this.tableInit.externalShareFilters) {
        this.tableInit.externalShareFilters(filters)
      }
    },
    receiveSorts(sorts) {
      this.sorts = sorts
      if (this.tableInit.externalShareSorts) {
        this.tableInit.externalShareSorts(sorts)
      }
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) =>
          row[this.tableInit.detailKeyId] !==
          targetRow[this.tableInit.detailKeyId]
      )
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const additionalQueries = this.tableInit.additionalQueries
      const params = {
        sorts,
        filters,
        additionalQueries,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true
      let eventData
      if (this.tableInit.action) {
        eventData = await this.tableInit.action(params)
      } else {
        const storeNamespace = this.tableInit.collection.replace(/^./, (str) =>
          str.toLowerCase()
        )
        const actionName =
          this.tableInit.vuexAction ||
          `${storeNamespace}/get${this.tableInit.collection}`
        eventData = await this.$store
          .dispatch(actionName, params)
          .catch(() => ({}))
      }
      if (this.latestRequestId !== requestId) {
        return
      }

      const events = eventData.data
      const eventsArray = events?.resultList || []
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.selectedRows = []
      this.tableProps.list = eventsArray.map((row) => {
        row.deleteMe = this.handleDelete.bind(this)
        return Object.keys(row).reduce((accumulator, key) => {
          accumulator[key] = this.specialTextFormatting[key]
            ? this.specialTextFormatting[key](row[key])
            : row[key]
          return accumulator
        }, {})
      })
      this.tableProps.total = events?.count || 0
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    enableExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) => r.roleName === 'can_export_tables'
      )
      this.tableProps.enableExport =
        !!canExportRole || this.tableInit.enableExportOverride
    },
  },
}
</script>
