import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import { store } from '@/state/store'

const getUserFullName = () => {
  const user = store.getters['auth/currentUser']
  return `${user?.firstName} ${user?.lastName}`
}

function promisify(syncFunction) {
  return new Promise((resolve) => resolve(syncFunction()))
}

export const calculatedValues = {
  'Range Start': () => promisify(() => DateTime.local().toISODate()),
  'Range End': () => promisify(() => DateTime.local().toISODate()),
  'Last 7 Days Start': () =>
    promisify(() => DateTime.local().minus({ days: 7 }).toISODate()),
  'Last 7 Days End': () => promisify(() => DateTime.local().toISODate()),
  'Next 7 Days Start': () => promisify(() => DateTime.local().toISODate()),
  'Next 7 Days End': () =>
    promisify(() => DateTime.local().plus({ days: 7 }).toISODate()),
  'This Month Start': () =>
    promisify(() => DateTime.local().startOf('month').toISODate()),
  'This Month End': () =>
    promisify(() => DateTime.local().endOf('month').toISODate()),
  'Last Month Start': () =>
    promisify(() =>
      DateTime.local().minus({ months: 1 }).startOf('month').toISODate()
    ),
  'Last Month End': () =>
    promisify(() =>
      DateTime.local().minus({ months: 1 }).endOf('month').toISODate()
    ),
  'Next Month Start': () =>
    promisify(() =>
      DateTime.local().plus({ months: 1 }).startOf('month').toISODate()
    ),
  'Next Month End': () =>
    promisify(() =>
      DateTime.local().plus({ months: 1 }).endOf('month').toISODate()
    ),
  'In The Future': () => promisify(() => DateTime.local().toISODate()),
  'In The Past': () => promisify(() => DateTime.local().toISODate()),
  currentUserFullName: () => getUserFullName(),
  'Today Start': () =>
    promisify(() => DateTime.local().startOf('day').toISODate()),
  'Today End': () => promisify(() => DateTime.local().endOf('day').toISODate()),
  'Tomorrow Start': () =>
    promisify(() =>
      DateTime.local().plus({ days: 1 }).startOf('day').toISODate()
    ),
  'Tomorrow End': () =>
    promisify(() =>
      DateTime.local().plus({ days: 1 }).endOf('day').toISODate()
    ),
}

export const datePredefined = [
  {
    text: 'Range',
    controlType: 'default-repeat',
    refreshOnSelect: false,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Range Start',
        recalculate: 'Range Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Range End',
        recalculate: 'Range End',
      },
    ],
  },
  {
    text: 'Today',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Today Start',
        recalculate: 'Today Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        hide: true,
        value: 'Today End',
        recalculate: 'Today End',
      },
    ],
  },
  {
    text: 'Tomorrow',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Tomorrow Start',
        recalculate: 'Tomorrow Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Tomorrow End',
        recalculate: 'Tomorrow End',
      },
    ],
  },
  {
    text: 'Last 7 days',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Last 7 Days Start',
        recalculate: 'Last 7 Days Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Last 7 Days End',
        recalculate: 'Last 7 Days End',
      },
    ],
  },
  {
    text: 'Next 7 days',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Next 7 Days Start',
        recalculate: 'Next 7 Days Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Next 7 Days End',
        recalculate: 'Next 7 Days End',
      },
    ],
  },
  {
    text: 'This month',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'This Month Start',
        recalculate: 'This Month Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'This Month End',
        recalculate: 'This Month End',
      },
    ],
  },
  {
    text: 'Last month',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Last Month Start',
        recalculate: 'Last Month Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Last Month End',
        recalculate: 'Last Month End',
      },
    ],
  },
  {
    text: 'Next month',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'Next Month Start',
        recalculate: 'Next Month Start',
      },
      {
        text: 'End',
        filterType: 'lte',
        value: 'Next Month End',
        recalculate: 'Next Month End',
      },
    ],
  },
  {
    text: 'In the past',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'lte',
        value: 'In The Past',
        recalculate: 'In The Past',
      },
    ],
  },
  {
    text: 'In the future',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Start',
        filterType: 'gte',
        value: 'In The Future',
        recalculate: 'In The Future',
      },
    ],
  },
]

export const noFutureDatesPredefined = (() => {
  const cloneDatePredefinedValues = deepClone(datePredefined)
  const whiteList = [
    'range',
    'today',
    'last 7 days',
    'this month',
    'in the past',
    'last month',
  ]
  return cloneDatePredefinedValues.filter((cloneDatePredefinedValue) =>
    whiteList.includes(cloneDatePredefinedValue.text.toLowerCase())
  )
})()

export const noPastDatesPredefined = (() => {
  const cloneDatePredefinedValues = deepClone(datePredefined)
  const whiteList = [
    'range',
    'today',
    'tomorrow',
    'next 7 days',
    'this month',
    'in the future',
    'next month',
  ]
  return cloneDatePredefinedValues.filter((cloneDatePredefinedValue) =>
    whiteList.includes(cloneDatePredefinedValue.text.toLowerCase())
  )
})()

export const numericRangePredefined = [
  {
    text: 'Range',
    controlType: 'default-repeat',
    refreshOnSelect: false,
    controls: [
      {
        text: 'Between',
        filterType: 'gte',
      },
      {
        text: 'And',
        filterType: 'lte',
      },
    ],
  },
  {
    text: 'Equals',
    controlType: 'default-repeat',
    refreshOnSelect: false,
    controls: [
      {
        text: 'Equals',
        filterType: 'eq',
      },
    ],
  },
]

export const customerPredefined = [
  {
    defaultOnSelection: true,
    text: 'Like',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'and',
    controls: [
      {
        text: 'Like',
        filterType: 'contains',
        //splitByPropIfArray: true
        splitByProp: true,
      },
    ],
  },
]

export const driverPredefined = [
  {
    defaultOnSelection: true,
    text: 'Like',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'and',
    controls: [
      {
        text: 'Like',
        filterType: 'contains',
        //splitByPropIfArray: true
        splitByProp: true,
      },
    ],
  },
]

export const userPredefined = [
  {
    text: 'Search',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'and',
    controls: [
      {
        text: 'Like',
        filterType: 'contains',
        splitByProp: true,
      },
    ],
  },
  {
    defaultOnSelection: true,
    text: 'Current User',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    controls: [
      {
        splitByProp: true,
        text: '',
        filterType: 'contains',
        value: 'currentUserFullName',
        recalculate: 'currentUserFullName',
      },
    ],
  },
]

export const textLike = [
  {
    defaultOnSelection: true,
    text: 'Like',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'and',
    controls: [
      {
        splitByProp: true,
        text: '',
        filterType: 'contains',
      },
    ],
  },
]

export const textLikeOrExact = [
  {
    defaultOnSelection: true,
    text: 'Like',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'or',
    controls: [
      {
        text: 'Like',
        filterType: 'contains',
      },
    ],
  },
]

export const textLikeOrExactForArrayProps = [
  {
    defaultOnSelection: true,
    text: 'Like',
    controlType: 'default-repeat',
    refreshOnSelect: true,
    childMethod: 'or',
    controls: [
      {
        text: 'Like',
        filterType: 'contains',
      },
    ],
  },
]

export const eldTypePredefined = [
  {
    text: 'Trak4',
    refreshOnSelect: true,
    controls: [
      {
        filterType: 'eq',
        value: 'Trak4',
      },
    ],
  },
  {
    text: 'Samsara',
    refreshOnSelect: true,
    controls: [
      {
        filterType: 'eq',
        value: 'Samsara',
      },
    ],
  },
  {
    text: 'KeepTruckin',
    refreshOnSelect: true,
    controls: [
      {
        filterType: 'eq',
        value: 'KeepTruckin',
      },
    ],
  },
]

export const assignmentPercentagePredefined = [
  {
    text: 'Not Assigned',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Equals',
        filterType: 'eq',
        value: '0',
      },
    ],
  },
  {
    text: 'Partially Assigned',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Between',
        filterType: 'neq',
        value: '0',
      },
      {
        text: 'And',
        filterType: 'lt',
        value: '100',
      },
    ],
  },
  {
    text: 'Fully Assigned',
    refreshOnSelect: true,
    controls: [
      {
        text: 'Equals',
        filterType: 'eq',
        value: '100',
      },
    ],
  },
]
