<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :key="idx"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <v-icon
              v-if="item.click"
              id="payments-table-actions-icon-click"
              :class="item.icon()"
              :color="item.color ? item.color() : 'primary'"
              @click="item.click"
            >
              {{ item.icon() }}
            </v-icon>
            <router-link
              v-if="item.path"
              id="payments-table-actions-link-item"
              target="_blank"
              :to="{ path: item.path() }"
              class="action-link"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div>
      <v-menu bottom transition="slide-x-transition">
        <template #activator="more">
          <v-icon color="primary" v-on="more.on">more_vert</v-icon>
        </template>
        <v-list>
          <v-list-tile
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
            :key="i"
          >
            <v-list-tile-title v-if="item.condition ? item.condition() : true">
              <router-link
                v-if="item.path"
                id="payments-table-actions-link-condition"
                target="_blank"
                :to="{ path: item.path() }"
                class="action-link"
              >
                <v-icon
                  v-if="item.icon"
                  :color="item.color ? item.color() : 'primary'"
                >
                  {{ item.icon() }}
                </v-icon>
                <span class="action-text">{{ item.title() }}</span>
              </router-link>
              <a
                v-if="item.click"
                id="payments-table-actions-href-action"
                class="action-link"
                @click="item.click"
              >
                <v-icon
                  v-if="item.icon"
                  :color="item.color ? item.color() : 'primary'"
                >
                  {{ item.icon() }}
                </v-icon>
                <span class="action-text">{{ item.title() }}</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
// .v-datatable .v-icon {
// TODO: [COACHRAIL-695] Make v-icons font size 20px and address scoped style issue
//   Currently this doesn't work.
// font-size: 20px !important;
// }

.action-links {
  height: 22px;
}

.action-links a,
.action-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
}

.action-text {
  padding-left: 8px;
}

.action-link-container {
  height: 22px;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }

  i {
    width: 22px;
    font-size: 22px;
  }
}
</style>
