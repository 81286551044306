<template>
  <v-container v-if="row.action === 'VOID_PAYMENT'">
    <Confirm
      v-if="!row.voidFlag"
      v-bind="voidConfirm"
      @cancel="collapseRow"
      @confirm="confirmVoid"
    />
  </v-container>
</template>

<script>
import { phoneFormatFilter } from '@/utils/phone'
import Confirm from '@/components/Confirm.vue'
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    Confirm,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reservation: {},
      loading: false,
      phoneFormatFilter,
      voidConfirm: {
        header: 'Void Payment',
        message: 'Are you sure you want to void this payment?',
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.loading = true
    this.$emit('row-expanded', this.props)
    this.loading = false
    if (this.row.action === 'VOID_PAYMENT' && this.row.voidFlag === true) {
      this.showAlert({
        type: 'error',
        message: 'This payment has already been voided.',
      })
      this.collapseRow()
    }
  },
  methods: {
    ...mapActions({
      voidReservationPayment: 'payments/voidReservationPayment',
      voidReferralPayment: 'payments/voidReferralPayment',
      showAlert: 'app/showAlert',
    }),
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
    collapseRow() {
      this.refreshQuery()
      this.props.expanded = false
    },
    async confirmVoid() {
      if (this.row.credits === null) return []
      const reservations = this.row.credits
        .map((c) => c.reservation)
        .filter((r) => r)
      const isReferralPayment = reservations.every(
        (r) => r.reservationType === 1
      )
      const isReservationPayment = reservations.every(
        (r) => r.reservationType === 0
      )
      if (!isReferralPayment && !isReservationPayment) {
        this.showAlert({
          type: 'error',
          message:
            'Cannot void payment. This payment contains reservations and referrals',
        })
      }
      const { paymentId } = this.row
      const voidPaymentPayload = {
        userId: this.currentUser?.userId,
        companyId: this.currentUser?.companyId,
        paymentId,
      }
      if (isReservationPayment) {
        const voidReservationPaymentResult = await this.voidReservationPayment(
          voidPaymentPayload
        )
        if (
          voidReservationPaymentResult &&
          voidReservationPaymentResult.status === 200
        ) {
          this.collapseRow()
        }
      } else if (isReferralPayment) {
        const voidReferralPaymentResult = await this.voidReferralPayment(
          voidPaymentPayload
        )
        if (
          voidReferralPaymentResult &&
          voidReferralPaymentResult.status === 200
        ) {
          this.collapseRow()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.padded {
  padding: 20px;
}

.float-right {
  float: right;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
</style>
