/**
 * Rounds a given input to a specified number of decimal places.
 *
 * @param input - The input number or string representation of a number to be rounded.
 * @param decimalPlaces - The number of decimal places to round the input to.
 *                       Must be a whole, positive integer.
 * @returns The input rounded to the specified number of decimal places as a number,
 *          or null if the input or decimalPlaces are invalid.
 */
export const round = (input: string | number, decimalPlaces: number): number | null => {
  // validate decimalPlaces is a whole, positive integer
  if (!Number.isInteger(decimalPlaces) || decimalPlaces < 0) {
    return null
  }

  const num = typeof input === 'string' ? parseFloat(input) : input
  if (isNaN(num) || !isFinite(num)) {
    return null
  }

  return +(num.toFixed(decimalPlaces))
}
