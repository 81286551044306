<template>
  <div>
    <div v-if="!isDialog">
      <div>
        <div>
          <div class="headline">
            {{ header }}
          </div>
          <span>
            {{ message }}
          </span>
          <br />
          <br />
        </div>
      </div>
      <div class="column is-5" />
      <div class="column">
        <div class="unpad-buttons">
          <v-btn
            :id="`${id}-confirm-button-cancel`"
            class="btn-secondaryaction"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            :id="`${id}-confirm-button-confirm`"
            class="delete-target"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="isDialog">
      <v-card class="cr-modal">
        <v-card-title>
          <h2>{{ header }}</h2>
        </v-card-title>
        <v-card-text>
          {{ message }}
          <br />
          <br />
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              :id="`${id}-confirm-button-dialog-cancel`"
              :loading="loading"
              class="btn-secondaryaction"
              @click="cancel"
            >
              {{ cancelButtonText }}
            </v-btn>
            <v-btn
              :id="`${id}-confirm-button-dialog-confirm`"
              :loading="loading"
              class="btn-primaryaction"
              @click="confirm"
            >
              {{ confirmButtonText }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, default: undefined },
    header: { type: String, default: undefined },
    message: { type: String, default: undefined },
    confirmButtonText: { type: String, default: 'Confirm' },
    cancelButtonText: { type: String, default: 'Cancel' },
    loading: { type: Boolean, default: false },
    isDialog: { type: Boolean, default: false },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-target {
  color: $white !important;
  background-color: $red !important;
}

.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.unpad-buttons button:first-child {
  margin-left: 0;
}
</style>
