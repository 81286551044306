/**
 * Formats a given phone number string into a standard US format.
 *
 * @param input - The phone number as a string, potentially including non-numeric characters.
 * @returns The formatted phone number string or an empty string if input is not a string.
 */
export const phoneFormatFilter = (input: string): string => {
  if (typeof input === 'string') {
    const inp = input.replace(/[^0-9]/gi, '')

    if (input.startsWith('1')) {
      return `(${inp.substring(1, 4)}) ${inp.substring(4, 7)}-${inp.substring(7)}`
    }

    return `(${inp.substring(0, 3)}) ${inp.substring(3, 6)}-${inp.substring(6)}`
  }
  return ''
}

/**
 * Formats a given phone number string into a standard format, optionally including the international code.
 *
 * @param input - The phone number as a string or number, potentially including non-numeric characters and international code.
 * @param stripCountryCode - A boolean indicating whether to strip the country code from the result.
 * @returns The formatted phone number string or null if the input cannot be formatted.
 */
export const phoneFormatFilterWithInternational = (
  input: string | number,
  stripCountryCode: boolean = false
): string | null => {
  const cleaned = ('' + input).replace(/\D/g, '')
  const match = RegExp(/^(1|)?(\d{3})(\d{3})(\d{4})$/).exec(cleaned)
  if (match) {
    if (stripCountryCode) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

/**
 * Formats a given phone number to a ten-digit format, removing any leading country code if present.
 *
 * @param phone - The phone number as a string, potentially including a leading '+' and country code.
 * @returns The ten-digit phone number string.
 */
export const formatPhoneNumberToTenDigit = (phone: string): string => {
  if (phone.startsWith('+')) {
    phone = phone.substring(1)
  }
  if (phone.length === 11 && phone.startsWith('1')) {
    phone = phone.substring(1)
  }
  return phone
}
