export const objectDataPaths = function (data) {
  const paths = []

  const buildObjectDataPaths = function (obj, path) {
    Object.keys(obj).forEach(function keysForEach(key) {
      let appendedPath = ''
      if (obj[key] && typeof obj[key] !== 'object') {
        appendedPath = `${path}/${key}`
        if (appendedPath[0] === '/') {
          appendedPath = appendedPath.substring(1, appendedPath.length)
        }
        paths.push(appendedPath)
      } else {
        appendedPath = `${path}/${key}`
        if (appendedPath[0] === '/') {
          appendedPath = appendedPath.substring(1, appendedPath.length)
        }
        paths.push(appendedPath)
        if (obj[key]) {
          buildObjectDataPaths(obj[key], appendedPath)
        }
      }
    })
  }
  buildObjectDataPaths(data, '')
  return paths
}
